import React, { useState, useEffect } from 'react';

// Styles object for inline styling
const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000
  },
  modal: {
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '20px',
    maxWidth: '90%',
    textAlign: 'center',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
  },
  title: {
    fontSize: '24px',
    marginBottom: '20px',
    color: '#333'
  },
  timerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px'
  },
  timerUnit: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 10px'
  },
  timerValue: {
    fontSize: '28px',
    fontWeight: 'bold',
    backgroundColor: '#f0f0f0',
    padding: '5px 10px',
    borderRadius: '8px',
    minWidth: '50px',
    color: '#333'
  },
  timerLabel: {
    marginTop: '5px',
    fontSize: '14px',
    color: '#666'
  },
  separator: {
    fontSize: '28px',
    color: '#999',
    margin: '0 0 30px 0'
  },
  closeButton: {
    backgroundColor: 'rgb(176 142 96)',
    color: 'white',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
    width: '100%',
    marginTop: '10px'
  }
};

const TOTAL_SECONDS = 72 * 60 * 60; // 72 hours in seconds

// If no end time exists, set it now
if (!localStorage.getItem('countdownEndTime')) {
  const newEndTime = Date.now() + (TOTAL_SECONDS * 1000);
  localStorage.setItem('countdownEndTime', newEndTime.toString());
}

const CountdownPopup = ({ isOpen, onClose }) => {
  // Calculate time left from the stored end time
  const calculateTimeLeft = () => {
    const storedEndTime = localStorage.getItem('countdownEndTime');
    const endTime = parseInt(storedEndTime);
    const difference = endTime - Date.now();
    
    if (difference <= 0) {
      localStorage.removeItem('countdownEndTime');
      return { hours: 0, minutes: 0, seconds: 0 };
    }

    const totalSeconds = Math.floor(difference / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return { hours, minutes, seconds };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft);

  useEffect(() => {
    const timer = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();
      setTimeLeft(newTimeLeft);

      // If timer reaches 0, clear interval and remove end time
      if (newTimeLeft.hours === 0 && 
          newTimeLeft.minutes === 0 && 
          newTimeLeft.seconds === 0) {
        clearInterval(timer);
        localStorage.removeItem('countdownEndTime');
        if (onClose) onClose();
      }
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(timer);
  }, [onClose]);

  const pad = (num) => num.toString().padStart(2, '0');

  if (!isOpen) return null;

  return (
    <div
      style={styles.overlay}
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onClose();
        }
      }}
    >
      <div style={styles.modal} onClick={(e) => e.stopPropagation()}>
        <h2 style={styles.title}>Live In</h2>

        <div style={styles.timerContainer}>
          <div style={styles.timerUnit}>
            <div style={styles.timerValue}>{pad(timeLeft.hours)}</div>
            <div style={styles.timerLabel}>Hours</div>
          </div>
          <div style={styles.separator}>:</div>
          <div style={styles.timerUnit}>
            <div style={styles.timerValue}>{pad(timeLeft.minutes)}</div>
            <div style={styles.timerLabel}>Mins</div>
          </div>
          <div style={styles.separator}>:</div>
          <div style={styles.timerUnit}>
            <div style={styles.timerValue}>{pad(timeLeft.seconds)}</div>
            <div style={styles.timerLabel}>Secs</div>
          </div>
        </div>

        <button onClick={onClose} style={styles.closeButton}>
          Close
        </button>
      </div>
    </div>
  );
};

export default CountdownPopup;