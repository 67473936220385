import axios from 'axios';
import React, { useState, useEffect, useContext, useRef } from 'react';

import { ethers } from 'ethers';
import CommonCSS from '../../assets/css/Common.css';
import SwapCSS from '../../assets/css/Swap.css';
import Logo from '../../assets/img/logo.png';
import Token from '../../assets/img/token.png';
import Phi from '../../assets/img/phi.png';
import usdt from '../../assets/img/usdt.png';
import WalletIcon from '../../assets/img/wallet-icon.png'; // Add a wallet icon image here
import { WalletContext } from './WalletContext';
import { useNavigate } from 'react-router-dom';
import name_logo from '../../assets/img/new_name_logo1.png'

const Header2 = ({phirate}) => {
    // function Header() {
    const { walletAddress, balance, isConnecting, connectWallet, disconnectWallet } = useContext(WalletContext);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [showWalletPopup, setShowWalletPopup] = useState(false);
    const [showWalletPopup1, setShowWalletPopup1] = useState(false);
    const [contractData, setContractData] = useState(null);
    const [hasConnected, setHasConnected] = useState(false);
    const [data,setData]=useState(null);
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const navigate = useNavigate();

    const dropdownRef = useRef(null);

    useEffect(() => {
        if (walletAddress && !isConnecting) {
            setHasConnected(true); // Once the wallet is connected, set this flag to true
        }
    }, [walletAddress, isConnecting]);

    const toggleDropdown = () => {
        // Only allow the dropdown to open if the wallet is connected and it isn't immediately after connecting
        if (walletAddress && hasConnected) {
            setDropdownOpen(!dropdownOpen);
        }
    };


    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    };

    useEffect(() => {
        if (dropdownOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownOpen]);


    const toggleWalletPopup = () => {
        setShowWalletPopup(!showWalletPopup);
    };
    const toggleWalletPopup1 = () => {
        setShowWalletPopup1(!showWalletPopup1);
    };





    const handleConnect = () => {
        // Additional logic for connecting the wallet
        if (!walletAddress) {
            alert('Please enter a wallet address.');
            return;
        }
        setShowWalletPopup1(false);
    };

    const shortenAddress = (address) => {
        return address ? `${address.slice(0, 5)}...${address.slice(-5)}` : '';
        // return address || '';
    };

    const fecth_data = async() => {
        try {
            const response = await axios.get(`${apiBaseUrl}/setting`);
            setData(response.data) 
        } catch (error) {
            console.log(error);
        }
       
    }

    useEffect(() => {
        fecth_data()
    },[]);

    return (
        <header className="header">
            <div className="left-section">
                <div className="logo2">
                    <img src={name_logo} style={{ marginTop: '5px' }} />
                </div>
                {/* <div className="balance-section">
                    <img src={Token} width={15} className='balance-section-img' style={{ marginRight: '8px' }} />
                     Rate: ${balance ? `$${parseFloat(balance).toFixed(3)}` : '$0.000'}
                </div> */}
                <div className="settings-icons">


                    <div className="network-status2" style={{fontSize:'12px'}}>
                    <span style={{fontSize:'9px'}}> PHI/USDT </span> <span style={{color:'green',fontSize:'9px'}}> ${phirate}</span>
                    </div>
                   
                </div>
            </div>

            <div className="right-section">


                {!walletAddress && (
                    <button
                        className="wallet-btn desktopbutton"
                        onClick={connectWallet}
                        // onClick={toggleWalletPopup1}
                        disabled={isConnecting}
                        style={{ fontSize: '12px' }}
                    >
                        {isConnecting ? 'Connecting...' : 'Connect Wallet'}
                    </button>
                )}

                {walletAddress && (
                    <div className="wallet-section">
                        <img src={WalletIcon} width={15} className='wallet-icon' onClick={toggleDropdown} />
                        <span className="wallet-address unique-wallet-address" onClick={toggleDropdown} style={{ fontSize: '12px' }}>
                            {shortenAddress(walletAddress)}
                        </span>

                        {dropdownOpen && (
                            <div className="wallet-dropdown" ref={dropdownRef}>
                                <ul>
                                    <li onClick={toggleWalletPopup}>Wallet</li>
                                    <li onClick={disconnectWallet}>Disconnect Wallet</li>
                                    {/* <li onClick={handledisConnect}>Disconnect Wallet</li> */}
                                </ul>
                            </div>
                        )}
                    </div>
                )}



            </div>

            {showWalletPopup && (
                <div className="wallet-popup-overlay">
                    <div className="wallet-popup">
                        <div className="wallet_popup_heading">
                            <h3>Your Wallet</h3>
                        </div>
                        <div className="wallet_popup_para">
                            <p style={{ color: 'black' }}><strong>Your Address:</strong></p>
                            <p> <span className='wallet_address_show_wallet'>{walletAddress}</span></p>
                            <button className="close-popup-btn" onClick={toggleWalletPopup}>Close</button>
                        </div>
                    </div>
                </div>
            )}

        </header>
    );
}

export default Header2;