// src/config/configProtection.js
import ConfigManager from './ConfigManager';

// Create a protected configuration instance
const protectedConfig = new ConfigManager({
  mode: 'production',
  apiEndpoint: 'https://api.yourapp.com/v1',
  debugMode: false
});

// Additional runtime protection
function setupConfigProtection() {
  try {
    // Remove developer tools detection bypass
    if (window.console) {
      const originalConsoleLog = window.console.log;
      const originalConsoleWarn = window.console.warn;
      const originalConsoleError = window.console.error;

      window.console.log = function() {
        // Optionally log or filter console messages
        if (protectedConfig.mode === 'development') {
          originalConsoleLog.apply(console, arguments);
        }
      };

      window.console.warn = function() {
        if (protectedConfig.mode === 'development') {
          originalConsoleWarn.apply(console, arguments);
        }
      };

      window.console.error = function() {
        if (protectedConfig.mode === 'development') {
          originalConsoleError.apply(console, arguments);
        }
      };
    }

    // Prevent adding new global variables
    if (Object.preventExtensions) {
      Object.preventExtensions(window);
    }

    // Periodic configuration validation
    const validationInterval = setInterval(() => {
      try {
        if (!protectedConfig.validateConfig()) {
          console.error('Configuration validation failed');
          clearInterval(validationInterval);
        }
      } catch (error) {
        console.error('Configuration protection error', error);
        clearInterval(validationInterval);
      }
    }, 60000); // Check every minute

  } catch (error) {
    console.error('Config protection setup failed', error);
  }
}

// Export the protected configuration
export { protectedConfig, setupConfigProtection };