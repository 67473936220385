import logo from './logo.svg';
import './App.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import Swap from './Views/Swap.js'
import Register from './Views/Auth/Register.js';
import Stack from './Views/Stack.js';
import Unstake from './Views/Unstake.js';
import Dashboard from './Views/User/Dashboard.js';
import DepositReport from './Views/User/Reports/Deposit_report.js';
import DirectReport from './Views/User/Reports/Direct_Report.js';
import RewardReport from './Views/User/Reports/Reward_Report.js';
import LevelReport from './Views/User/Reports/Level_Report.js';
import WithdrawReport from './Views/User/Reports/Withdraw_Report.js';
import StakingBonusReport from './Views/User/Reports/StakingBonus_Report.js';
import AboutUs from './Views/User/AboutUs.js';
import CommingSoon from './Views/CommingSoon.js';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { WalletProvider } from './Views/Common/WalletContext.js';
import Team from './Views/Team/team.js';
import My_downline from './Views/Team/my_downline.js';
import Level_show from './Views/Team/level_show.js';
import CryptoPrices from './Views/test.js';
import StakingReport from './Views/User/Reports/staking_report.js';
import RedeemReport from './Views/User/Reports/redeem_report.js';
import StakingTransferReport from './Views/User/Reports/staketranfer_report.js';
import RedeemtransferReport from './Views/User/Reports/redeemtranfer_report.js';
import StakingProfitReport from './Views/User/Reports/stakeprofit_tranfer.js';
import NewSwap from './Views/NewToken/NewSwap.js';
import Transfer from './Views/NewToken/Transfer.js';
import TokenSwap from './Views/NewToken/TokenSwap.js';
import Test from './Views/Common/test.js';
import { protectedConfig, setupConfigProtection } from './config/configProtection';


setupConfigProtection();

function App() {

  const appMode = protectedConfig.mode;


  return (
    <div className="App">
            {/* <h1>Current App Mode: {appMode}</h1> */}

      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
          <Router>
        <Routes>
          {/* <Route path="/" element={<App />} /> */}
          <Route path="/register/:walletAddress?" element={<Register />} />
          <Route path="/" element={<Swap />} />
          <Route path="/swap" element={<Swap />} />
          <Route path="/stack" element={<Stack />} />
          <Route path="/unstake" element={<Unstake />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/deposit_report" element={<DepositReport />} />
          <Route path="/direct_report" element={<DirectReport />} />
          <Route path="/reward_report" element={<RewardReport />} />
          <Route path="/level_report" element={<LevelReport />} />
          <Route path="/withdraw_report" element={<WithdrawReport />} />
          <Route path="/stakingbonus_report" element={<StakingBonusReport />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/comingSoon" element={<CommingSoon />} />
          <Route path="/team" element={<Team />} />
          <Route path="/my_downline/:level" element={<My_downline />} />
          <Route path="/staking_report" element={<StakingReport />} />
          <Route path="/redeem_report" element={<RedeemReport />} />
          <Route path="/level_show" element={<Level_show />} />
          <Route path="/testing" element={<CryptoPrices />} />
          <Route path="/stakingtranfer_report" element={<StakingTransferReport />} />
          <Route path="/redeemtransfer_report" element={<RedeemtransferReport />} />
          <Route path="/stakeprofit_report" element={<StakingProfitReport />} />
          <Route path="/newSwap" element={<NewSwap />} />
          <Route path="/transfer" element={<Transfer />} />
          <Route path="/tokenSwap" element={<TokenSwap />} />
          <Route path="/test" element={<Test />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
