import React from "react";
import '../../assets/css/Dashboard.css';
import Header from '../Common/Header';
import Footer from '../Common/Footer';

const AboutUs = () => {
    return (
        <>
            <Header />
            <div className="terms-container mt-5" style={{marginTop:'65px'}}>
                <div className="terms-header-section">
                    <h1 className="terms-title">PHI Description</h1>
                    <p className="terms-update">Last Updated: Sep 2024</p>
                </div>
                <div className="terms-body-section">
                    <section className="terms-section">
                        <h2 className="terms-subtitle">Introduction</h2>
                        <p className="terms-text">
                            Once synonymous with the concept of "money," gold has long been a substance of immense
                            intrigue and historical significance. The Incas referred to it as “tears of the sun,” while Homer, in
                            both the Iliad and the Odyssey, described it as “the glory of the immortals and a sign of wealth
                            among ordinary men.” Its importance is underscored throughout both the Old and New
                            Testaments, cementing gold's timeless and universal cultural relevance.

                        </p>
                        <p className="terms-text">
                            However, gold today is no longer synonymous with "money." Instead, we now have a diverse
                            array of currencies, each tied to a sovereign political authority, primarily used within national
                            borders. These national monetary systems were originally linked to gold, either directly or
                            through a peg to the U.S. dollar. The primary function of paper money was to represent physical
                            gold in a more convenient form for transactions.

                        </p>
                        <p className="terms-text">
                            By making gold easier to divide, store, and transport, representative paper money enhanced its
                            economic utility, greatly stimulating trade. Yet, the superior ease of gold-backed paper introduced
                            a new problem: trust in its scarcity. Unlike physical gold, which is constrained by natural limits,
                            there is no such restriction on the amount of paper notes that can be issued. This opened the
                            door for dishonest issuers to print more notes than the gold reserves they held, adding a new
                            layer of counterparty risk. As a result, gold-backed paper became a less reliable store of value
                            than gold itself.

                        </p>
                        <p className="terms-text">
                            This erosion of trust led many nations to repatriate their gold from U.S. vaults starting in 1965.
                            Decades earlier, the Bretton Woods agreement had established the U.S. dollar as the world’s
                            reserve currency, with major currencies convertible into U.S. dollars, and the dollar itself tied to
                            gold at a rate of $35 per ounce. Consequently, much gold flowed into the U.S., which exported
                            dollars in return. However, as these flows reversed, U.S. gold reserves diminished rapidly,
                            prompting the unilateral suspension of the dollar’s convertibility into gold. This decision
                            effectively severed gold from the international monetary system, ushering in the era of unbacked,
                            fiat currency.

                        </p>
                        <p className="terms-text">
                            Today, a new entity, PHI Commodities Limited (Phi Gold), is digitizing the value of gold through a
                            novel token, Phi Gold. This innovative token allows holders to own digitally allocated gold in
                            small, highly transferable amounts, all with relatively low fees. This whitepaper delves into the
                            principles of monetary systems, illustrating how Phi Gold introduces a new level of accessibility
                            and trust in the evolving financial ecosystem.
                        </p>
                        <p className="terms-text">
                            The Changing Landscape of Finance
                        </p>
                        <p className="terms-text">
                            For many modern finance professionals, the concept of fixed exchange rates tied to the U.S.
                            dollar or gold may seem unfamiliar. Since the 1970s, exchange rates have been allowed to "float,"
                            meaning they are determined by global markets...
                        </p>
                        <p className="terms-text">
                            Foreign exchange markets are based on supply and demand, relative to other fiat currencies. This new
                            system had several major implications for global finance.
                        </p>
                        <p className="terms-text">
                            First, the global supply of money was no longer pegged in any way to the global supply of gold. This led
                            to sharply rising gold prices and high levels of inflation within many domestic economies. With these new
                            fiat money supplies being wholly unpegged from gold, the scarcity of money became artificially ensured.
                            Trust in the physical scarcity of gold, and of its existence within vaults, was replaced by trust in the
                            economic stewardship of national monetary authorities.

                        </p>
                        <p className="terms-text">
                            Second, and relatedly, was the emergence of global foreign exchange markets. With rates no longer
                            fixed, market mechanisms were required to discover the relative prices of foreign currency. Whereas the
                            fixed exchange rates under the gold-based system largely precluded speculation, freely floating rates
                            transformed the foreign exchange markets into a global arena for speculative activity, wholly separate
                            from the production of goods and services. The Bank of International Settlements has reported that since
                            1992, foreign exchange turnover has clearly increased more than the underlying economic activity,
                            whether measured by GDP, equity turnover, or gross trade flows.

                        </p>
                        <p className="terms-text">
                            These and other issues associated with physically unconstrained fiat monies are what have perpetuated
                            the economic function of gold. Despite its lesser monetary role, gold remains a universally popular ‘safehaven’ asset and is still held by many central banks. There are an estimated 6.1 billion ounces of physical
                            gold above ground, of which about half are connected to financial markets, implying a market cap of
                            approximately $4.7 trillion USD. With over $200 billion in combined daily trading volume, gold markets
                            are among the most liquid in the world. One major reason that gold continues to be held in many
                            investment portfolios is as a hedge against financial instability.

                        </p>

                        <ol type="i">
                            <li><strong>Fiat Money & Financial Instability</strong> </li>
                            <p className="terms-text">
                                The post-Bretton Woods era of unbacked, fiat money has coincided with numerous periods of
                                currency-related financial instability around the world. The Latin American debt crisis of the early 1980s,
                                the 1997 Asian financial crisis, and many other smaller-scale emergencies all had their roots in this
                                relatively new global monetary system. More recently, the Global Financial Crisis shed light on the
                                financial turbulence associated with debt-based fiat money. Its aftermath, an unprecedented global
                                monetary expansion coordinated by leading central banks, has raised concerns about these monetary
                                authorities’ economic stewardship.
                            </p>
                            <ol type="1">
                                <li>Michael R. King, Dagfinn Rime, “The $4 trillion question: what explains FX growth since the 2007
                                    survey?”, Bank of International Settlements Quarterly Review, December 2010, at p. 30.
                                </li>
                                <li>Based on January 2, 2020 closing gold prices.</li>
                            </ol>
                            <li><strong>Bitcoin & Digital Tokens</strong> </li>
                            <p className="terms-text">
                                The Bitcoin network commenced on January 3rd, 2009, and was originally introduced as “a purely
                                peer-to-peer version of electronic cash [that] would allow online payments to be sent from one party to
                                another, without going through a financial institution.” This description speaks to Bitcoin’s financial
                                aspect (payments), but there is also a fully internal, decentrally secured monetary unit: bitcoins.
                                Massively enhanced global payments efficiency is a major financial innovation, but the invention of
                                digital scarcity – e.g., a hard cap of 21 million bitcoins – is a landmark monetary innovation; it is why
                                some call bitcoin “digital gold.”
                            </p>
                            <p className="terms-text">
                                Embedded within the first bitcoin ‘block’ was the following text: “The Times 03/Jan/2009 Chancellor
                                on brink of second bailout for banks.” This British headline was a reference to the fallout from the Global
                                Financial Crisis, hinting at the motivation of Bitcoin’s pseudonymous creator, ‘Satoshi Nakamoto’. Bitcoin
                                and its underlying technologies seem intended to address problems and moral hazards associated with
                                fiat money and the implied concentration of economic power in the hands of monetary authorities and
                                major banks.
                            </p>
                            <p className="terms-text">
                                With truly humble origins, bitcoins were first used to purchase a real-world item in 2010, when 10,000
                                bitcoins were used to pay for two pizzas in Jacksonville, Florida. Bitcoin’s market value has since
                                experienced a tumultuous rise, regularly expanding by multiples and then contracting by over 50%. For
                                example, in 2011, the price first reached $1 USD, surged to $31, and then fell back to $2. Reaching
                                $1,000 in 2013, bitcoin’s price peaked at $19,500 in 2017 before falling back to $3,500, and was
                                approximately $7,200 as of midnight UTC on January 1, 2020. Though generally trending upwards,
                                bitcoin’s meteoric rise has been accompanied by extreme volatility.  </p>
                            <p className="terms-text">
                                These massive price movements caused media coverage of bitcoin to soar, particularly during the
                                latter half of 2017. With value and popularity continuing to rise, politicians and financial regulators began
                                paying attention. Amidst the frenzy surrounding this still-nascent technology, many around the world
                                began to ask: is bitcoin money? For its biggest proponents, bitcoin was undoubtedly money, and of an
                                entirely superior form. However, for its detractors, bitcoin could never be money; it was associated with
                                nefarious activities, was not “backed” by any government, and its price was far too volatile.
                            </p>
                            <p className="terms-text">
                                These debates raised the question: what is money? With dictionary definitions lacking consensus on its
                                meaning, one popular way to conceptualize this English term is by considering the following three
                                functions: ‘medium-of-exchange’, ‘store-of-value’, and ‘unit-of-account’. For bitcoin’s detractors, the
                                regularity of major price movements precluded bitcoin from being a store-of-value, meaning it could not
                                be money. The purchasing-power uncertainty associated with holding this volatile asset would preclude
                                its widespread use as a medium-of-exchange.

                            </p>
                            <li><strong>Stablecoins</strong> </li>
                            <p className="terms-text">
                                With bitcoin’s price volatility seemingly holding back a ground-breaking enhancement to the
                                transactional utility of money (i.e., medium-of-exchange functionality, cheap and fast global value
                                transfers, etc.), attempts were made to create digital tokens with stable values. This was done by
                                pegging the value of a digital token to a fiat monetary unit, to achieve the best of both worlds: the
                                transactional utility of a decentralized digital token, but the price stability of fiat money. These projects
                                came to be known as ‘stablecoins’.</p>
                            <p className="terms-text">Tether was created on October 6th, 2014, in response to a clear market need to transfer funds quickly
                                and globally, whilst benefiting from the relative stability of fiat money. Since then, the value of
                                outstanding tethers has surpassed $4 billion, with versions now available both in numerous fiat
                                denominations and on a variety of blockchains. Price stability is maintained by Tether always valuing the
                                token at the monetary unit to which it is pegged, a policy supported by holding reserves equal in fiat
                                value to all outstanding tethers. Maintenance of this policy generates arbitrage activity in the secondary
                                markets, keeping prices near their pegged fiat values.</p>
                            <p className="terms-text">With the highest daily traded volume of any digital token, Tether is the undisputed global leader in fiatbacked stablecoins. Built on top of open blockchain technologies but backed by reserves, Tether’s
                                effective unbundling of monetary principles marks a new frontier in the evolution of money, particularly
                                regarding transactional utility.
                            </p>
                        </ol>

                    </section>

                    <section className="terms-section">
                        <h2 className="terms-subtitle">The Evolution of Money:</h2>
                        <p className="terms-text">
                            Before the emergence of Bitcoin and its underlying technologies, there were no alternatives to fiat
                            money. Now, with plausible alternatives, individuals are beginning to make their own trade-offs between
                            monetary principles in their choices of money. Dramatically accelerated by the emergence of Bitcoin and
                            its underlying technologies, the evolution of money has long been characterized by an unbundling of
                            monetary principles.
                        </p>
                        <p className="terms-text">
                            Phi’s existing line of fiat-backed ‘stablecoins’ create value by unbundling and
                            repackaging the monetary principles associated with fiat money and bitcoin, respectively. In
                            exchange for bearing the counterparty risk of Phi, the issuer, holders of Phi tokens are
                            rewarded with a transactional utility far exceeding that of traditional fiat money, all while
                            maintaining the price stability of the domestic fiat monetary unit.
                            Phi’s introduction of a gold-backed product is a natural progression in the evolution
                            of money. Since gold is more physically cumbersome than fiat money, the incremental benefit
                            of a gold-backed stablecoin is commensurately greater than for its fiat-backed counterparts. In
                            the Phi Gold token, users will have access to an asset with a scarcity basis and price stability
                            akin to gold, but with the transactional utility of a digital token – a novel permutation of
                            financial characteristics. By relentlessly expanding the potential for individuals to make their
                            own trade-offs between monetary principles, Phi has become a global leader in financial
                            empowerment.

                        </p>
                        <p className="terms-text">
                            - - - - - - -
                        </p>
                        <p className="terms-text">
                            Short term. These monetary principles are best conceived of as heavily preference-laden. This is because
                            individuals imbue their financial activity with their own preferences, particularly regarding time.
                        </p>
                        <p className="terms-text">
                            Predating modern securities markets and the globalization of financial services, markets for physical
                            gold have a history that stretches back to antiquity. When gold was used as money, its price was
                            expressed in terms of other goods, such as food items or metals. However, in the modern financial
                            system, the price of physical gold is most commonly expressed in fiat money, particularly U.S. dollars.
                            Whether gold is held as a safe-haven asset, traded for speculative purposes, or included in a diversified
                            investment portfolio, the primary focus is on exposure to the fiat money price change of gold. Over time,
                            new financial products have been designed to provide this exposure without the need to hold physical
                            gold. There are currently three primary ways to gain economic exposure to the price of gold:

                        </p>
                        <ol type="i">
                            <li><strong> Physical Gold</strong>
                            </li>
                            <p className="terms-text">
                                The global standard for investment-grade physical gold is the London bullion market, a wholesale
                                over-the-counter (OTC) market for trading gold and silver. Trading is conducted among members of the
                                London Bullion Market Association (LBMA), loosely overseen by the Bank of England. The LBMA issues a
                                Good Delivery specification, which sets the rules regarding the physical characteristics of gold and silver
                                bars used in settlement. Since most members are major international bullion dealers and refiners, the
                                public typically accesses this market indirectly, through third-party channels.
                            </p>
                            <li><strong>Exchange-Traded Products
                            </strong> </li>
                            <p className="terms-text">
                                Exchange-traded gold products include exchange-traded funds (ETFs), exchange-traded notes (ETNs),
                                and closed-end funds (CEFs), all traded like shares on major stock exchanges. Gold ETFs represent a large
                                portion of the gold market, allowing investors to invest small amounts of capital more effectively. These
                                products vary, with some tracking the price of physical gold and others investing in gold-related
                                companies. While they offer indirect exposure to gold’s price changes, they spare investors the burden
                                of physical gold storage and verification.

                            </p>
                            <li><strong>Derivatives</strong> </li>
                            <p className="terms-text">
                                Financial derivatives such as gold forwards, futures, and options are another primary way for
                                investors to gain exposure to the fiat price changes of physical gold. These products trade on public
                                exchanges globally, as well as in various OTC private markets. Gold futures are particularly useful as
                                hedging tools for commercial producers and users of gold, facilitating global gold price discovery and
                                portfolio diversification. Derivatives enable purchasers to customize their exposure to physical gold
                                based on specific factors like strike price and duration.

                            </p>
                        </ol>
                        <p className="terms-text">1. Physical Gold</p>
                        <p className="terms-text">Pros: Direct exposure to the liquid gold market.</p>
                        <p className="terms-text">Cons: High costs of storage, transfer, and verification, making it impractical for smaller investors.</p>
                        <br />
                        <p className="terms-text">2. Exchange-Traded Products (ETPs)</p>
                        <p className="terms-text">Pros: Lower costs, easy access, widely available.</p>
                        <p className="terms-text">Cons: Only indirect exposure to gold; counterparty risks exist, as investors don't have specific allocations
                            of physical gold.</p>
                        <br />
                        <p className="terms-text">3. Gold Derivatives</p>
                        <p className="terms-text">Pros: No counterparty risks, customizable exposure.</p>
                        <p className="terms-text">Cons: Complex, not cost-effective for average investors.</p>
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead class="thead-light">
                                    <tr>
                                        <th></th>
                                        <th>Gold Exposure Type</th>
                                        <th>Costs</th>
                                        <th>Accessibility</th>
                                        <th>Transactional Utility</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>Physical Gold</th>
                                        <td>Direct</td>
                                        <td>High</td>
                                        <td>Low</td>
                                        <td>Very Low</td>
                                    </tr>
                                    <tr>
                                        <th>Gold ETFs</th>
                                        <td>Indirect</td>
                                        <td>Low</td>
                                        <td>High</td>
                                        <td>Moderate</td>
                                    </tr>
                                    <tr>
                                        <th>PHI Gold</th>
                                        <td>Direct</td>
                                        <td>Low</td>
                                        <td>High</td>
                                        <td>Very High</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </section>

                    <section className="terms-section">
                        <h2 className="terms-subtitle">Pure Hallmark Ingots (Phi)</h2>
                        <p className="terms-text">
                            The economic alternatives to holding physical gold have expanded, allowing investors to make trade-offs
                            between monetary principles, such as accepting indirect exposure (with counterparty risk) for lower costs
                            and easier access. However, traditional markets still lack a low-cost method for direct exposure to physical
                            gold prices.</p>
                        <p className="terms-text">
                            Phi Gold seeks to combine the advantages of these methods by offering:
                            Direct exposure to physical gold prices,
                            High accessibility akin to exchange-traded products (ETFs),
                            Enhanced transactional utility through digital tokens.
                            Comparison of Gold Exposure Methods</p>
                        <p className="terms-text">
                            Phi Gold will apply the same technology and functionality as Tether’s fiat-backed stablecoins to physical
                            gold, dramatically mobilizing gold markets and lowering barriers to entry for non-institutional investors. Phi
                            Gold will mirror many features of existing Phi products but with key differences, including the creation of a
                            new entity to issue Phi Gold tokens.
                        </p>
                        <p className="terms-text">
                            Basic Functionality of Phi Gold
                            Each Phi token represents 1 troy fine ounce of London Good Delivery gold stored in a vault in Switzerland.
                            The tokens are fractionalized up to six decimal places (i.e., increments as small as 0.000001 troy fine ounce)
                        </p>
                    </section>
                    <section className="terms-section">
                        <h2 className="terms-subtitle">Redemption Process for Phi Tokens</h2>
                        <p className="terms-text">
                            1. Redeeming for Physical Gold:
                            Holders of Phi tokens can redeem them for physical gold in Switzerland, subject to fees and minimum
                            redemption requirements.
                            The gold can be delivered to a location in Switzerland chosen by the holder, or they can opt to sell the
                            gold and take the proceeds.
                        </p>
                        <p className="terms-text">
                            2. Gold Bar Allocation:
                            Each on-chain address holding Phi tokens will be associated with specific gold bars.
                            A Look-up Website will allow holders to see the specific gold bar(s) associated with their address.
                            When Phi tokens are transferred, the physical gold will be reallocated to minimize the number of bars
                            associated with each address.

                        </p>
                        <p className="terms-text">
                            3. Verification Process:
                            To purchase or redeem Phi, users must complete identity verification and agree to specific terms of
                            service.
                            Minimum purchase and redemption amounts apply.

                        </p>
                    </section>
                    <section className="terms-section">
                        <h2 className="terms-subtitle">Product Details & Fees
                        </h2>
                        <p className="terms-text">
                            1. Availability:
                            Phi tokens will initially be available on the Bep20 protocol, with other protocols to follow.
                            Purchases and redemptions will be in U.S. dollars.</p>
                        <p className="terms-text">
                            2. Gold Storage:
                            Phi Gold will buy London Good Delivery gold from a Swiss gold dealer, stored in a vault in Switzerland
                            for the benefit of Phi token holders.
                        </p>
                        <p className="terms-text">
                            The custodian will hold insurance, but it may not cover the full value of the stored gold.</p>
                        <p className="terms-text">
                            3. Fees:
                            No gas fees for Phi transactions, only fees for issuance and redemption.
                            A minimum purchase of 50 fine troy oz (50 Phi tokens) is required, with a 25 basis point (bp) fee on the
                            purchase.
                            A minimum redemption of 430 fine troy oz (430 Phi tokens) is required to redeem for physical gold.</p>
                    </section>
                    <section className="terms-section">
                        <h2 className="terms-subtitle">Redemption Process for Full Bars of Gold</h2>
                        <p className="terms-text">
                            1. Gold Bar Size Variations:
                            Redemptions can only be made for full gold bars, which typically range from 385 to 415 fine troy
                            ounces.if the bar is larger than 430 oz, holders must: Provide extra Phi tokens to cover the difference.
                            Or pay the excess value in fiat currency. If the bar is smaller than 430 oz, the holder will receive gold
                            equivalent to the actual bar size.
                        </p>
                        <p className="terms-text">
                            2. Delivery & Sale:
                            Physical delivery in Switzerland will incur a 25 basis point (bp) fee based on the current Swiss market
                            price of gold, plus the cost of delivery.
                            Holders may opt for selling the gold through Phi Gold. If successful, the proceeds will be returned
                            minus a 25 bp fee.</p>
                        <p className="terms-text">
                            3. Fees & Requirements:
                            Current fees, minimum redemption, and purchase amounts are subject to change and are outlined in
                            Phi Gold’s terms of service.</p>
                    </section>
                    <section className="terms-section">
                        <h2 className="terms-subtitle">Potential Use Cases for Phi Gold
                        </h2>
                        <p className="terms-text">
                            1. Highly Mobile 'Safe-Haven' Asset:
                            Gold is traditionally seen as a safe-haven asset, but its physical nature makes it hard to store and trade.
                            Phi Gold enables global, digital ownership and transfer of gold’s economic value, making it easier to
                            hold and trade the asset without moving the physical gold itself

                        </p>
                        <p className="terms-text">
                            2. Liquid & Effective Portfolio Hedge:
                            Gold is often used as a hedge against financial instability. However, smaller investors face challenges in
                            accessing physical gold. Phi Gold democratizes access by offering smaller quantities of gold at
                            wholesale prices, making it a liquid and cost-effective hedge for more investors.
                        </p>
                        <p className="terms-text">
                            3. Sovereign-Neutral Monetary Unit:
                            Phi combines the scarcity and stability of gold with the utility of digital tokens, potentially creating a
                            sovereign-neutral monetary unit.
                            Unlike Bitcoin, which suffers from price volatility, Phi Gold offers price stability and the potential to
                            become a widely traded, global asset outside governmental control.
                            This could serve as the basis for global trade and gold-based contracting, empowering individuals to
                            adhere to a modern form of the gold standard.
                        </p>
                    </section>
                    <section className="terms-section">
                        <h2 className="terms-subtitle">Purchaser Sophistication

                        </h2>
                        <p className="terms-text">
                            Phi tokens are speculative and involve a high degree of risk and uncertainty. The sale of Phi tokens
                            is aimed solely at persons who have sufficient knowledge and understanding of blockchain and
                            cryptographic tokens and other digital assets, smart contracts, storage mechanisms (such as digital or
                            token wallets), and blockchain or distributed ledger technology, to be able to evaluate the risks and
                            merits of a purchase of Phi tokens and are able to bear the risks thereof, including loss of all amounts
                            paid and loss of all Phi tokens purchased. Potential purchasers must independently assess, after making
                            such investigations as they consider necessary, the merits of, and their suitability for, purchasing any
                            Phi tokens. Potential purchasers and should consult their accounting, legal and tax representatives and
                            advisers in order to evaluate the economic, legal and tax consequences of purchasing Phi tokens.
                        </p>
                    </section>
                    <section className="terms-section">
                        <h2 className="terms-subtitle">No Representations, Warranties or Liability</h2>
                        <p className="terms-text">
                            Recipients of this whitepaper must not rely on information contained in this whitepaper or any
                            inferences drawn from it. Purchasers of Phi tokens must determine for themselves the information
                            they require and base any decision on their own information, investigation, analysis and advice and not
                            on the information contained in this whitepaper. No Phi Gold Associate makes or purports to make,
                            and each hereby disclaims, any representation, warranty, condition, covenant or undertaking (whether
                            express or implied) in any form whatsoever to any entity or person, including any representation,
                            warranty or undertaking in relation to the truth, accuracy and completeness of any of the information
                            set out in this whitepaper. In particular, no Phi Gold Associate makes any representation or warranty
                            (whether express or implied) as to the accuracy or the completeness of the information contained in
                            this whitepaper, and none of them have any liability for any statement, forecast, information or
                            matter, express or implied, arising out of or contained in or derived from, or for any omission from this
                            whitepaper or any other information communicated to any person in relation to or in connection with
                            the matters contained in this whitepaper. To the maximum extent permitted by any and all applicable
                            laws, no Phi Gold Associate shall be liable for any direct, indirect, special, incidental, consequential,
                            punitive or other losses of any kind, in tort, contract, equity, common law or otherwise (including but
                            not limited to loss of revenue, income or profits, and loss of use or data), arising out of or in connection
                            with any acceptance of or reliance on this whitepaper or any part thereof. For clarity, no regulatory
                            authority has examined or approved of any of the information set out in this whitepaper. No such
                            action has been or will be taken under the laws of any jurisdiction. Tether Gold reserves the right to
                            modify and replace Phi tokens from time to time in its sole discretion and has no obligation to update
                            this whitepaper, including to reflect any such modification or replacement.

                        </p>
                    </section>
                    <section className="terms-section">
                        <h2 className="terms-subtitle">Private Presentation</h2>
                        <p className="terms-text">
                            Neither this whitepaper, or any part of it, may be reproduced, distributed or disseminated by any
                            person other than those persons authorised to do so by Phi Gold.</p>
                    </section>
                    <section className="terms-section">
                        <h2 className="terms-subtitle">Forward Looking Statements
                        </h2>
                        <p className="terms-text">
                            Certain statements contained in this whitepaper may constitute
                            forward-looking statements or speak to future events or plans. These forward-looking
                            statements or information involve known and unknown risks and uncertainties, which may
                            cause actual events to differ materially. No reliance should be placed on any forward-looking
                            statements or information.
                            Risk Factors. Purchasing the Phi tokens entails risks and purchasers could lose their entire
                            purchase amount or all of their Phi tokens. Certain risks are described in the risk disclosure
                            statement that is referenced in and forms a part of the Terms.
                            Your Promises, Representation and Warranties By accessing, receiving or accepting possession
                            of this whitepaper (or any part of it), you acknowledge, agree, represent and warrant to Phi
                            Gold that: (1) you have read and understood the risk disclosure statement that is referenced in
                            and forms a part of the Terms; (2) that you are not a Restricted Person; (3) the distribution or
                            dissemination to you of this whitepaper, and any part of it, or its receipt or acceptance by you,
                            is not prohibited or restricted by the applicable laws in your jurisdiction, and where any
                            restrictions in relation to your receipt, acceptance or possession are applicable, you have and
                            will observe and comply with all such restrictions at your own expense and without liability to
                            any Phi Gold Associate ; and (4) all of the matters set out in, referred to in these Important
                            Considerations is accepted and understood by you.</p>

                    </section>

                </div>
            </div>
            <Footer />
        </>
    );
};

export default AboutUs;
