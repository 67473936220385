import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import '../../../assets/css/Report.css';
import Header from '../../Common/Header';
import Footer from '../../Common/Footer';
import { useNavigate } from 'react-router-dom';
import { WalletContext } from '../../Common/WalletContext';
import StakingBonusReportLogo from '../../../assets/img/staking_bonus_report_logo.png';

const StakingProfitReport = () => {
    const { walletAddress, balance, isConnecting, connectWallet, disconnectWallet } = useContext(WalletContext);
    const [userID, setUserID] = useState(null);
    const [stakingBonusReport, setStakingBonusReport] = useState([]); // Initialize as an empty array
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const navigate = useNavigate();
    // Fetch Staking bonus report data based on userID
    const checkWalletAndFetchData = async (walletAddress) => {
        if (!walletAddress) {
            console.log('Wallet not connected. Waiting...');
            // alert('Connect Your Wallet');
            return; // Return early if walletAddress is not available.
        }

        try {
            const response = await axios.get(`${apiBaseUrl}/getuser/${walletAddress}`);
            const userData = response.data;
            const userID = userData?.id;

            if (!userID) {
                console.log('User not found, navigating to register.');
                navigate('/register'); // Navigate if userID is not found
            } else {
                localStorage.setItem('user_ID', userID);
                // fetchHomepageData(userID); // Uncomment if needed
            }
        } catch (error) {
            console.error('Error fetching user data');
        }
    };

    useEffect(() => {
        const userID = localStorage.getItem('user_ID');
        if (walletAddress) {
            const fetcStakingBonusReport = async () => {
                try {
                    const reason = "stake_profit";
                    const response = await axios.get(`${apiBaseUrl}/Transaction/${walletAddress}/${reason}`);
                    if (response.data && response.data.transaction && response.data.transaction.data) {
                        setStakingBonusReport(response.data.transaction.data);
                    } else {
                        setStakingBonusReport([]); // Set to an empty array if no data
                    }
                } catch (error) {
                    console.error('Error fetching deposit report data');
                    setStakingBonusReport([]); // Set to an empty array if error occurs
                }
            };
            checkWalletAndFetchData(walletAddress);

            fetcStakingBonusReport();
        }
    }, [userID]);

    // Function to apply inline styles based on status
    const getStatusStyle = (status) => {
        if (status.toLowerCase() === 'completed') {
            return { backgroundColor: '#d1efff' }; // Green background
        } else if (status.toLowerCase() === 'rejected') {
            return { backgroundColor: '#fff2cb' }; // Orange background
        } else if (status.toLowerCase() === 'pending') {
            return { backgroundColor: '#ffdde0' }; // Red background
        }
        return {};
    };

    const formatDate = (dateString) => {
        const months = [
            'Jan', 'Feb', 'March', 'April', 'May', 'June',
            'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
        ];

        const date = new Date(dateString);
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();

        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        return `${day} ${month} ${year} | ${hours}:${minutes}:${seconds}`;
    };

    return (
        <>
            <Header setUserID={setUserID} />

            <div className="container main_container_height" style={{ marginTop: '65px' }}>
                <h2 className="text-center mb-4 network_heading">Staking Profit History</h2>
                {stakingBonusReport.length > 0 ? (
                    stakingBonusReport.map((report, index) => (
                        <div key={index} >
                            {report.reciver === walletAddress &&
                                <div className="team-transaction-box shadow">
                                    <div className="flex-grow-1">
                                        <div className="team-transaction-name-2 mb-2">utility: Phi Token</div>
                                        <div className='d-flex justify-content-between'>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <span className="report-date" style={{ fontSize: '12px', color: '#000' }}>
                                                    {formatDate(report.created_at)}
                                                </span>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <h6 className='' style={{ color: '#000' }}>${report.amount}</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="deposit-icon d-flex flex-column align-items-center">
                                        {/* {/ Icon can be added here if needed /} */}
                                    </div>
                                </div>
                            }

                        </div>
                    ))
                ) : (
                    <p className="text-center">No Staking Profit Available</p>
                )}
            </div>


            {/* <div className="container main_container_height" style={{marginTop:'65px'}}>
                <h2 className="text-center mb-4 network_heading">Staking Profit History</h2>
                {stakingBonusReport.length > 0 ? (
                    stakingBonusReport.map((report, index) => (
                        <div key={index} >
                        {report.reciver === walletAddress &&
                            <div className="team-transaction-box shadow">
                                <div className="flex-grow-1">
                                    <div className="team-transaction-name-2 mb-2">{report.sender}</div>
                                    {/* <div className='d-flex justify-content-between'>
                                        <small className=""><strong>Date:{' '}</strong> {formatDate(report.created_at)}</small>
                                        <div><strong>Value :</strong> ${report.amount}</div>
                                        </div>
                                    <div className='d-flex justify-content-between'>
                                        <div style={{ display: 'flex', alignItems: 'center' }}><span className="report-date" style={{ fontSize: '12px', color: '#0d6efd' }}>{formatDate(report.created_at)}</span><br /></div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}><h6 className='transaction-btn2'>${report.amount}</h6></div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}><h6 className='transaction-btn2'>Level {report.level}</h6></div>
                                    </div>
                                    {/* <p><strong>Stake :</strong> {report.status}</p> 

                                </div>
                                <div className="deposit-icon d-flex flex-column align-items-center">
                                    {/* <img src={DepositeLogo} alt="Deposit Icon" /> 
                                </div>
                            </div>
                        }

                    </div>
                    ))
                ) : (
                    <p className="text-center">No Staking Profit Available</p>
                )}
            </div>  */}

            {/* <div className='d-flex justify-content-between'>
                                        <small className=""><strong>Date:{' '}</strong> {formatDate(report.created_at)}</small>
                                        <div><strong>Value :</strong> ${report.amount}</div>
                                        </div> */}
            <Footer />
        </>
    );
};

export default StakingProfitReport;
