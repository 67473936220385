import React, { useEffect, useState } from 'react';

import Header from '../Views/Common/Header';
import Footer from '../Views/Common/Footer';

import ComingSoonCss from '../assets/css/ComingSoon.css';
import comingSoonImage from '../assets/img/comming_soon.png'; // Replace with your image path

const ComingSoon = () => {
    const [timeRemaining, setTimeRemaining] = useState('');

    // Function to calculate time remaining until midnight
    const calculateTimeUntilMidnight = () => {
        const now = new Date();
        const midnight = new Date();
        midnight.setHours(24, 0, 0, 0); // Set to next midnight
        const timeDifference = midnight - now; // Difference in milliseconds

        const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
        const seconds = Math.floor((timeDifference / 1000) % 60);

        return `${hours}h ${minutes}m ${seconds}s`;
    };

    useEffect(() => {
        // Set interval to update every second
        const timer = setInterval(() => {
            setTimeRemaining(calculateTimeUntilMidnight());
        }, 1000);

        // Cleanup interval on component unmount
        return () => clearInterval(timer);
    }, []);

    return (
        <>
            <div className='CommingSoonBody'>
                <Header />
                <div className="coming-soon-container" style={{ marginTop: '150px' }}>
                    {/* <img src={comingSoonImage} alt="Coming Soon" width={350} className="coming-soon-image" /> */}
                    <div className="coming-soon-text">
                        <p>PHI Network</p>
                        <p>Almost Live!</p>
                        <p>Stay Tuned...</p>
                        <p>{timeRemaining}</p>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default ComingSoon;


// import React from 'react';

// import Header from '../Views/Common/Header';
// import Footer from '../Views/Common/Footer';

// import ComingSoonCss from '../assets/css/ComingSoon.css';
// import comingSoonImage from '../assets/img/comming_soon.png'; // Replace with your image path

// const ComingSoon = () => {
//     // Get current date and time
//     const currentDate = new Date();
//     const formattedDate = currentDate.toLocaleDateString('en-US', {
//         year: 'numeric',
//         month: 'long',
//         day: 'numeric',
//     });
//     const formattedTime = currentDate.toLocaleTimeString('en-US', {
//         hour: '2-digit',
//         minute: '2-digit',
//         second: '2-digit',
//     });

//     return (
//         <>
//             <div className='CommingSoonBody'>
//                 <Header />
//                 <div className="coming-soon-container" style={{ marginTop: '45px' }}>
//                     <img src={comingSoonImage} alt="Coming Soon" width={350} className="coming-soon-image" />
//                     <div className="coming-soon-text">
//                         <p>Coming Soon</p>
//                         <p>Date: {formattedDate}</p>
//                         <p>Time: {formattedTime}</p>
//                     </div>
//                 </div>
//                 <Footer />
//             </div>
//         </>
//     );
// };

// export default ComingSoon;


// import React from 'react';

// import Header from '../Views/Common/Header';
// import Footer from '../Views/Common/Footer';

// import ComingSoonCss from '../assets/css/ComingSoon.css';
// import comingSoonImage from '../assets/img/comming_soon.png'; // Replace with your image path

// const ComingSoon = () => {
//     return (
//         <>
//             <div className='CommingSoonBody'>
//                 <Header />
//                 <div className="coming-soon-container" style={{marginTop:'45px'}}>
//                     <img src={comingSoonImage} alt="Coming Soon" width={350} className="coming-soon-image" />
//                     {/* <div className="coming-soon-text">Coming Soon</div> */}
//                 </div>
//                 <Footer />
//             </div>
//         </>
//     );
// };

// export default ComingSoon;
