class ConfigManager {
    constructor(initialConfig) {
      // Use a symbol to create truly private properties
      const _config = Symbol('config');
      const _locked = Symbol('locked');
  
      this[_config] = { ...initialConfig };
      this[_locked] = true;
  
      // Create a proxy to prevent direct modifications
      return new Proxy(this, {
        get(target, prop) {
          // Prevent accessing private symbols
          if (typeof prop === 'symbol') {
            throw new Error('Private properties cannot be accessed');
          }
  
          // Only allow accessing config properties
          return target[_config][prop];
        },
        set(target, prop, value) {
          // Prevent modifications if locked
          if (target[_locked]) {
            console.warn('Configuration is locked and cannot be modified');
            return false;
          }
  
          // Allow modification only for existing properties
          if (prop in target[_config]) {
            target[_config][prop] = value;
            return true;
          }
  
          console.warn('Cannot add new properties to configuration');
          return false;
        }
      });
    }
  
    // Method to unlock configuration (with a secret)
    unlock(secretKey) {
      if (secretKey === process.env.REACT_APP_CONFIG_SECRET) {
        this[Symbol('locked')] = false;
        return true;
      }
      return false;
    }
  
    // Method to get current configuration
    getConfig() {
      return { ...this[Symbol('config')] };
    }
  
    // Method to validate configuration
    validateConfig() {
      // Implement server-side validation if needed
      return true;
    }
  }
  
  export default ConfigManager;