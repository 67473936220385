import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { WalletProvider } from './Views/Common/WalletContext';

// Prevent developer tools from modifying global objects
import { setupConfigProtection } from './config/configProtection';

// Set up config protection
setupConfigProtection();

// Prevent direct developer tools access
Object.defineProperty(window, 'devtools', {
  configurable: false,
  get() {
    throw new Error('Developer tools access blocked');
  }
});




ReactDOM.render(
  <WalletProvider>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </WalletProvider>,
  document.getElementById('root')
);