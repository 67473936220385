import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';

const Test = () => {
    const [homepageData, setHomepageData] = useState(null);
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        // Only fetch data if walletAddress exists
            const fetchHomepageData = async () => {
                try {
                    const response = await axios.get(`${apiBaseUrl}/homepageapi/uk`);
                    setHomepageData(response.data);
                    console.log('Homepage Data:', response.data);
                } catch (error) {
                    console.error('Error fetching homepage data:', error);
                    // Optional: Add toast error notification
                    // toast.error('Failed to fetch homepage data');
                }
            };

            fetchHomepageData();
        
    }, [ apiBaseUrl]); // Dependencies ensure the effect runs when these change

    return (
        <>
            {homepageData ? (
                <div>
                    {/* Render your data here */}
                    {JSON.stringify(homepageData)}
                </div>
            ) : (
                <div>Loading...</div>
            )}
        </> 
    );
}

export default Test;