import axios from 'axios';
import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import '../../../assets/css/Report.css';
import Header from '../../Common/Header';
import Footer from '../../Common/Footer';
import { useNavigate } from 'react-router-dom';
import { WalletContext } from '../../Common/WalletContext';

const StakingReport = () => {
    const { walletAddress } = useContext(WalletContext);
    const [userID, setUserID] = useState(null);
    const [depositReport, setDepositReport] = useState([]);
    const [page, setPage] = useState(1);
    const [limit] = useState(10);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const navigate = useNavigate();
    const loadingRef = useRef(false);

    const checkWalletAndFetchData = useCallback(async (walletAddress) => {
        if (!walletAddress) {
            console.log('Wallet not connected. Waiting...');
            return;
        }

        try {
            const response = await axios.get(`${apiBaseUrl}/getuser/${walletAddress}`);
            const userData = response.data;
            const userID = userData?.id;

            if (!userID) {
                console.log('User not found, navigating to register.');
                navigate('/register');
            } else {
                localStorage.setItem('user_ID', userID);
                setUserID(userID);
            }
        } catch (error) {
            console.error('Error fetching user data');
        }
    }, [apiBaseUrl, navigate]);

    const fetchDepositReport = useCallback(async (walletAddress, currentPage) => {
        if (loadingRef.current || !hasMore) return;

        loadingRef.current = true;
        setLoading(true);
        try {
            const response = await axios.get(`${apiBaseUrl}/Transaction/${walletAddress}/staking_bonus`, {
                params: {
                    page: currentPage,
                    limit: limit,
                }
            });

            const fetchedData = response.data.transaction.data;

            if (fetchedData.length > 0) {
                setDepositReport(prevReports => {
                    const newReports = [...prevReports];
                    fetchedData.forEach(newItem => {
                        if (!newReports.some(existingItem => existingItem.id === newItem.id)) {
                            newReports.push(newItem);
                        }
                    });
                    return newReports;
                });
                setPage(prevPage => prevPage + 1);
            } else {
                setHasMore(false);
            }
        } catch (error) {
            console.error('Error fetching deposit report data');
            setHasMore(false);
        } finally {
            setLoading(false);
            loadingRef.current = false;
        }
    }, [apiBaseUrl, limit, hasMore]);

    useEffect(() => {
        if (walletAddress) {
            checkWalletAndFetchData(walletAddress);
            fetchDepositReport(walletAddress, 1);
        }
    }, [walletAddress, checkWalletAndFetchData, fetchDepositReport]);

    useEffect(() => {
        const handleScroll = () => {
            if (
                window.innerHeight + document.documentElement.scrollTop >=
                document.documentElement.offsetHeight - 100
            ) {
                if (hasMore && !loadingRef.current) {
                    fetchDepositReport(walletAddress, page);
                }
            }
        };

        const debouncedHandleScroll = debounce(handleScroll, 200);

        window.addEventListener('scroll', debouncedHandleScroll);
        return () => window.removeEventListener('scroll', debouncedHandleScroll);
    }, [walletAddress, page, hasMore, fetchDepositReport]);

    const formatDate = (dateString) => {
        const months = [
            'Jan', 'Feb', 'March', 'April', 'May', 'June',
            'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
        ];

        const date = new Date(dateString);
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();

        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        return `${day} ${month} ${year} | ${hours}:${minutes}:${seconds}`;
    };

    // Debounce function
    function debounce(func, wait) {
        let timeout;
        return function executedFunction(...args) {
            const later = () => {
                clearTimeout(timeout);
                func(...args);
            };
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
        };
    }

    return (
        <>
            <Header setUserID={setUserID} />
            <div className="container main_container_height" style={{ marginTop: '65px' }}>
                <h2 className="text-center mb-4 network_heading">Staking Bonus History</h2>
                {depositReport.length > 0 ? (
                    depositReport.map((report, index) => (

                        <div key={report.id} >
                            {report.reciver === walletAddress && (
                                <div className="flex-grow-1 team-transaction-box shadow">
                                    <div className="team-transaction-name-2 mb-2">{report.sender}</div>
                                    <div className='d-flex justify-content-between'>
                                        <div>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <span className="report-date" style={{ fontSize: '12px', color: '#000' }}>
                                                    <strong>Level :</strong> {report.level}
                                                </span>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <span className="report-date" style={{ fontSize: '12px', color: '#000' }}>
                                                    {formatDate(report.created_at)}
                                                </span>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <h6 className='' style={{ color: '#000' }}>${report.amount}</h6>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="deposit-icon d-flex flex-column align-items-center">
                                {/* Icon can be added here if needed */}
                            </div>

                        </div>

                    ))
                ) : (
                    <p className="text-center">No My Staking History Available</p>
                )}

                {loading && <p className="text-center">Loading more...</p>}
                {!hasMore && depositReport.length > 0 && <p className="text-center">No more data to load</p>}
            </div>
            <Footer />
        </>
    );
};

export default StakingReport;