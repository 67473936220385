import React, { useState, useEffect } from 'react';
import axios from 'axios';

const PopularTokens = ({ phirate }) => {
    const [tokens, setTokens] = useState([
        { 
            symbol: 'PHI', 
            name: 'phi', 
            logo: require('../../assets/img/new_logo.png'),
            price: phirate || null,
            priceChange: null 
        },
        { 
            symbol: 'BTC', 
            name: 'bitcoin', 
            logo: 'https://cryptologos.cc/logos/bitcoin-btc-logo.png',
            price: null,
            priceChange: null 
        },
        { 
            symbol: 'ETH', 
            name: 'ethereum', 
            logo: 'https://cryptologos.cc/logos/ethereum-eth-logo.png',
            price: null,
            priceChange: null 
        },
        { 
            symbol: 'BNB', 
            name: 'binancecoin', 
            logo: 'https://cryptologos.cc/logos/bnb-bnb-logo.png',
            price: null,
            priceChange: null 
        },
        { 
            symbol: 'SOL', 
            name: 'solana', 
            logo: 'https://cryptologos.cc/logos/solana-sol-logo.png',
            price: null,
            priceChange: null 
        },
        { 
            symbol: 'LTC', 
            name: 'litecoin', 
            logo: 'https://cryptologos.cc/logos/litecoin-ltc-logo.png',
            price: null,
            priceChange: null 
        },
        { 
            symbol: 'AVAX', 
            name: 'avalanche-2', 
            logo: 'https://cryptologos.cc/logos/avalanche-avax-logo.png',
            price: null,
            priceChange: null 
        },
        { 
            symbol: 'LINK', 
            name: 'chainlink', 
            logo: 'https://cryptologos.cc/logos/chainlink-link-logo.png',
            price: null,
            priceChange: null 
        },
        { 
            symbol: 'UNI', 
            name: 'uniswap', 
            logo: 'https://cryptologos.cc/logos/uniswap-uni-logo.png',
            price: null,
            priceChange: null 
        },
        { 
            symbol: 'DOT', 
            name: 'polkadot', 
            logo: 'https://cryptologos.cc/logos/polkadot-new-dot-logo.png',
            price: null,
            priceChange: null 
        },
        { 
            symbol: 'ATOM', 
            name: 'cosmos', 
            logo: 'https://cryptologos.cc/logos/cosmos-atom-logo.png',
            price: null,
            priceChange: null 
        },
        { 
            symbol: 'XRP', 
            name: 'ripple', 
            logo: 'https://cryptologos.cc/logos/xrp-xrp-logo.png',
            price: null,
            priceChange: null 
        },
        { 
            symbol: 'ADA', 
            name: 'cardano', 
            logo: 'https://cryptologos.cc/logos/cardano-ada-logo.png',
            price: null,
            priceChange: null 
        },
        { 
            symbol: 'MATIC', 
            name: 'matic-network', 
            logo: 'https://cryptologos.cc/logos/polygon-matic-logo.png',
            price: null,
            priceChange: null 
        },
        { 
            symbol: 'DOGE', 
            name: 'dogecoin', 
            logo: 'https://cryptologos.cc/logos/dogecoin-doge-logo.png',
            price: null,
            priceChange: null 
        }
    ]);

    const [lastPrices, setLastPrices] = useState({});

    useEffect(() => {
        const fetchTokenPrices = async () => {
            try {
                // Fetch current prices
                const priceResponse = await axios.get('https://api.coingecko.com/api/v3/simple/price', {
                    params: {
                        ids: 'binancecoin,ethereum,bitcoin,dogecoin,cardano,solana,ripple,chainlink,phi,polkadot,matic-network,avalanche-2,uniswap,litecoin,cosmos',
                        vs_currencies: 'usd'
                    }
                });

                // Fetch 24h ago prices
                const historicalResponse = await axios.get('https://api.coingecko.com/api/v3/coins/markets', {
                    params: {
                        vs_currency: 'usd',
                        ids: 'binancecoin,ethereum,bitcoin,dogecoin,cardano,solana,ripple,chainlink,polkadot,matic-network,avalanche-2,uniswap,litecoin,cosmos',
                        order: 'market_cap_desc',
                        per_page: 250,
                        page: 1,
                        sparkline: false,
                        price_change_percentage: '24h'
                    }
                });

                const historicalPrices = historicalResponse.data.reduce((acc, coin) => {
                    acc[coin.id] = {
                        current_price: coin.current_price,
                        price_change_percentage_24h: coin.price_change_percentage_24h
                    };
                    return acc;
                }, {});

                const updatedTokens = tokens.map(token => {
                    if (token.symbol === 'PHI') {
                        // Special handling for PHI
                        const currentPhiPrice = phirate ? parseFloat(phirate).toFixed(4) : null;
                        const lastPhiPrice = '0.1618';
                        
                        const priceChange = lastPhiPrice && currentPhiPrice 
                            ? ((currentPhiPrice - lastPhiPrice) / lastPhiPrice * 100).toFixed(2)
                            : null;

                        return { 
                            ...token, 
                            price: currentPhiPrice,
                            priceChange: priceChange
                        };
                    }

                    // For other tokens
                    const historicalData = historicalPrices[token.name];
                    const price = priceResponse.data[token.name]?.usd;
                    
                    return { 
                        ...token, 
                        price: price ? price.toFixed(2) : null,
                        priceChange: historicalData ? historicalData.price_change_percentage_24h.toFixed(2) : null
                    };
                });

                // Update last prices for next comparison
                const newLastPrices = updatedTokens.reduce((acc, token) => {
                    if (token.price) {
                        acc[token.name] = parseFloat(token.price);
                    }
                    return acc;
                }, {});
                setLastPrices(newLastPrices);

                setTokens(updatedTokens);
            } catch (error) {
                console.error('Error fetching token prices:', error);
            }
        };

        fetchTokenPrices();
        const interval = setInterval(fetchTokenPrices, 180000); // Refresh every 3 minute

        return () => clearInterval(interval);
    }, [phirate]);

    return (
        <div className="popular-tokens-container" style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '10px',
            backgroundColor: '#f4f4f4',
            borderRadius: '8px',
            margin: '10px 10px 80px 10px',
        }}>
            {tokens.map((token, index) => (
                <div 
                    key={token.symbol} 
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '10px',
                        borderBottom: index < tokens.length - 1 ? '1px solid #e0e0e0' : 'none'
                    }}
                >
                    <img 
                        src={token.logo} 
                        alt={`${token.name} logo`} 
                        style={{
                            width: '40px', 
                            height: '40px', 
                            borderRadius: '50%',
                            marginRight: '15px'
                        }} 
                    />
                    <div style={{ 
                        display: 'flex', 
                        justifyContent: 'space-between', 
                        flexGrow: 1,
                        alignItems: 'center' 
                    }}>
                        <div style={{ fontWeight: 'bold' }}>{token.symbol}</div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                            <div style={{color: token.priceChange >= 0 ? 'green' : 'red' }}>
                                {token.price ? `$${token.price}` : 'Loading...'}
                            </div>
                            {token.priceChange !== null && (
                                <div style={{ 
                                    color: token.priceChange >= 0 ? 'green' : 'red',
                                    fontSize: '0.8em'
                                }}>
                                    {token.priceChange >= 0 ? '▲' : '▼'} {Math.abs(token.priceChange)}%
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default PopularTokens;